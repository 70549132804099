import "@fontsource/rakkas";
import "@fontsource/noto-sans-hk";
import "@fontsource/lora";
import "@fontsource/lora/400-italic.css";

import { WrapPageElement } from "./src/components/WrapPageElement";
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

import "./src/styles/global.scss";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
    element,
}) => {
    return <WrapPageElement>{element}</WrapPageElement>;
};
